export function isNotEmpty(value: any): boolean {
  if (value == null || typeof value == "undefined") return false;
  return value.length > 0;
}

export function isNumber(num: any): boolean {
  return num.length > 0 && !isNaN(num);
}

export function isPhoneNumber(value: any): boolean {
  const regex =
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
  return regex.test(String(value).toLowerCase());
}

export function isEmail(email: string): boolean {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return regex.test(String(email).toLowerCase());
}

export function fieldValidation(field: any, validationFunction: any): any {
  if (field == null) return false;

  const isFieldValid = validationFunction(field.value);
  if (!isFieldValid) {
    field.className = field.className.includes("--invalid")
      ? field.className
      : field.className + "--invalid";
  } else {
    field.className = field.className.includes("invalid")
      ? field.className.substring(0, field.className.indexOf("--invalid"))
      : field.className;
  }

  return isFieldValid;
}
