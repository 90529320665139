
import "./privacy-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import { FETCH_PRIVACY_PAGE } from "@/store/modules/privacy-page/privacy-page.actions";
import { PRIVACY_PAGE_ENTRY_ID } from "@/store/api.config";
import { PrivacyPageEntity } from "@/store/modules/privacy-page/privacy-page.model";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "PrivacyPage",
  components: {
    Page,
    Panel,
    RichTextRenderer,
  },
})
export default class PrivacyPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "PrivacyPolicy",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  privacyPolicyPageEntity: PrivacyPageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_PRIVACY_PAGE, PRIVACY_PAGE_ENTRY_ID);
    this.privacyPolicyPageEntity = this.$store.getters.privacyPolicyPageEntity;

    this.isLoading = false;
  }
  //#endregion
}
PrivacyPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
