
import "./conditions-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import { FETCH_CONDITIONS_PAGE } from "@/store/modules/conditions-page/conditions-page.actions";
import { CONDITIONS_PAGE_ENTRY_ID } from "@/store/api.config";
import { ConditionsPageEntity } from "@/store/modules/conditions-page/conditions-page.model";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "ConditionsPage",
  components: {
    Page,
    Panel,
    RichTextRenderer,
  },
})
export default class ConditionsPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "TermsAndConditions",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  conditionsPageEntity: ConditionsPageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_CONDITIONS_PAGE, CONDITIONS_PAGE_ENTRY_ID);
    this.conditionsPageEntity = this.$store.getters.conditionsPageEntity;

    this.isLoading = false;
  }
  //#endregion
}
ConditionsPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
