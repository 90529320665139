
import "./quote-form.scss";
import "@/validation/validator.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { QuotePageEntity } from "@/store/modules/quote-page/quote-page.model";
import emailjs from "emailjs-com";
import {
  isNotEmpty,
  isEmail,
  isNumber,
  fieldValidation,
} from "@/validation/validator";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import Applink from "@/components/applink/applink.vue";

@Options({
  name: "QuoteForm",
  components: {
    RichTextRenderer,
    Applink,
  },
})
export default class QuoteForm extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };

  @Prop() quotePageEntity: QuotePageEntity;

  validatePhoneNumber = false;

  address: string | null = null;
  propertyType: string | null = "House";
  systemSize: string | null = "6.6 kW";
  roofMaterial: string | null = "Tin";
  name: string | null = null;
  email: string | null = null;
  phone: string | null = null;

  addressInvalidErrorMessage: string | null = null; // Required field
  nameInvalidErrorMessage: string | null = null; // Required field
  emailInvalidErrorMessage: string | null = null; // Invalid email provided
  phoneInvalidErrorMessage: string | null = null; // Invalid phone number provided

  formFields: {
    address: HTMLElement;
    propertyType: HTMLElement;
    systemSize: HTMLElement;
    roofMaterial: HTMLElement;
    name: HTMLElement;
    email: HTMLElement;
    phone: HTMLElement;
  };

  validateForm(): boolean {
    this.formFields = {
      address: document.getElementById("address"),
      propertyType: document.getElementById("property-type"),
      systemSize: document.getElementById("system-size"),
      roofMaterial: document.getElementById("roof-material"),
      name: document.getElementById("name"),
      email: document.getElementById("email"),
      phone: document.getElementById("phone"),
    };

    this.addressInvalidErrorMessage = fieldValidation(
      this.formFields.address,
      isNotEmpty
    )
      ? null
      : "Required field";

    this.nameInvalidErrorMessage = fieldValidation(
      this.formFields.name,
      isNotEmpty
    )
      ? null
      : "Required field";

    this.emailInvalidErrorMessage = fieldValidation(
      this.formFields.email,
      isEmail
    )
      ? null
      : "Invalid email provided";

    if (this.validatePhoneNumber) {
      this.phoneInvalidErrorMessage = fieldValidation(
        this.formFields.phone,
        isNumber
      )
        ? null
        : "Invalid phone number provided";
    } else this.phoneInvalidErrorMessage = null;

    let valid = true;
    valid = this.nameInvalidErrorMessage ? false : valid;
    valid = this.emailInvalidErrorMessage ? false : valid;
    valid = this.phoneInvalidErrorMessage ? false : valid;
    return valid;
  }

  sendEmail(): void {
    if (this.validateForm()) {
      try {
        emailjs.sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_QUOTE_FORM_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_EMAILJS_USER_ID
        );
        this.$toast.open({
          message: "Request sent",
          type: "success",
          duration: 4000,
          dismissible: true,
        });
        this.resetForm();
      } catch (error) {
        console.log({ error });
        this.$toast.open({
          message: "Form submission error",
          type: "error",
          duration: 4000,
          dismissible: true,
        });
      }
    } else {
      this.$toast.open({
        message: "Quote form invalid",
        type: "error",
        duration: 4000,
        dismissible: true,
      });
    }
  }

  resetForm(): void {
    this.address = null;
    this.propertyType = "House";
    this.systemSize = "1.5 kW";
    this.roofMaterial = "Tin";
    this.name = null;
    this.email = null;
    this.phone = null;
  }
}
