<template>
  <div class="quote-form-container">
    <div class="quote-form">
      <h2 class="quote-form__header">
        {{ this.quotePageEntity.quotePageHeaderText }}
      </h2>
      <div class="quote-form__rich-text">
        <RichTextRenderer :document="this.quotePageEntity.quotePageRichText" />
      </div>
      <form ref="form" @submit.prevent="sendEmail">
        <div class="form-field">
          <div class="form-label">
            <label>Address&nbsp;<a style="color: red">*</a></label>
            <label class="form-label__error">{{
              this.addressInvalidErrorMessage
            }}</label>
          </div>
          <input
            id="address"
            name="address"
            class="form-field__input"
            type="text"
            v-model="address"
          />
        </div>
        <div class="form-field">
          <div class="form-label">
            <label>Property Type</label>
          </div>
          <select
            name="property-type"
            id="property-type"
            class="form-field__select"
            v-model="propertyType"
          >
            <option value="House">House</option>
            <option value="Townhouse">Townhouse</option>
            <option value="Unit">Unit</option>
            <option value="Apartment">Apartment</option>
            <option value="Warehouse">Warehouse</option>
            <option value="Solar Farm">Solar Farm</option>
          </select>
        </div>
        <div class="form-field">
          <div class="form-label">
            <label>System Size</label>
          </div>
          <select
            name="system-size"
            id="system-size"
            class="form-field__select"
            v-model="systemSize"
          >
            <option value="6.6 kW">6.6 kW</option>
            <option value="8 kW">8 kW</option>
            <option value="10 kW">10 kW</option>
            <option value="15 kW">15 kW</option>
            <option value="20 kW">20 kW</option>
            <option value="25 kW">25 kW</option>
            <option value="50 kW">50 kW</option>
            <option value="100 kW">100 kW</option>
            <option value="200 kW">200 kW</option>
            <option value="500 kW">500 kW</option>
            <option value="1 mW">1 mW</option>
          </select>
        </div>
        <div class="form-field">
          <div class="form-label">
            <label>Roof Material</label>
          </div>
          <select
            name="roof-material"
            id="roof-material"
            class="form-field__select"
            v-model="roofMaterial"
          >
            <option value="Tin">Tin</option>
            <option value="Concrete Tile">Concrete Tile</option>
            <option value="Terracotta Tile">Terracotta Tile</option>
            <option value="Klip-lok">Klip-lok</option>
            <option value="Slate">Slate</option>
            <option value="Asphalt">Asphalt</option>
            <option value="Shingle">Shingle</option>
            <option value="Fibro">Fibro</option>
            <option value="Asbestos">Asbestos</option>
            <option value="Timber">Timber</option>
            <option value="EPDM">EPDM</option>
            <option value="Decromastic">Decromastic</option>
            <option value="Flat concrete">Flat concrete</option>
            <option value="Not sure">Not sure</option>
          </select>
        </div>
        <div class="form-field">
          <div class="form-label">
            <label>Name&nbsp;<a style="color: red">*</a></label>
            <label class="form-label__error">{{
              this.nameInvalidErrorMessage
            }}</label>
          </div>
          <input
            id="name"
            name="name"
            class="form-field__input"
            type="text"
            v-model="name"
          />
        </div>
        <div class="form-field">
          <div class="form-label">
            <label>Email&nbsp;<a style="color: red">*</a></label>
            <label class="form-label__error">{{
              this.emailInvalidErrorMessage
            }}</label>
          </div>
          <input
            id="email"
            name="email"
            class="form-field__input"
            type="text"
            v-model="email"
          />
        </div>
        <div class="form-field">
          <div class="form-label">
            <label>Phone</label>
            <label class="form-label__error">{{
              this.phoneInvalidErrorMessage
            }}</label>
          </div>
          <input
            id="phone"
            name="phone"
            class="form-field__input"
            type="text"
            v-model="phone"
          />
        </div>
        <input type="submit" value="Request Quote" />
        <div class="quote-form__terms-and-conditions">
          <Applink
            to="/terms-and-conditions"
            activeClass="applink--active--white"
            inactiveClass="applink--inactive--white"
          >
            <small>Terms and Conditions</small>
          </Applink>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import "./quote-form.scss";
import "@/validation/validator.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { QuotePageEntity } from "@/store/modules/quote-page/quote-page.model";
import emailjs from "emailjs-com";
import {
  isNotEmpty,
  isEmail,
  isNumber,
  fieldValidation,
} from "@/validation/validator";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import Applink from "@/components/applink/applink.vue";

@Options({
  name: "QuoteForm",
  components: {
    RichTextRenderer,
    Applink,
  },
})
export default class QuoteForm extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };

  @Prop() quotePageEntity: QuotePageEntity;

  validatePhoneNumber = false;

  address: string | null = null;
  propertyType: string | null = "House";
  systemSize: string | null = "6.6 kW";
  roofMaterial: string | null = "Tin";
  name: string | null = null;
  email: string | null = null;
  phone: string | null = null;

  addressInvalidErrorMessage: string | null = null; // Required field
  nameInvalidErrorMessage: string | null = null; // Required field
  emailInvalidErrorMessage: string | null = null; // Invalid email provided
  phoneInvalidErrorMessage: string | null = null; // Invalid phone number provided

  formFields: {
    address: HTMLElement;
    propertyType: HTMLElement;
    systemSize: HTMLElement;
    roofMaterial: HTMLElement;
    name: HTMLElement;
    email: HTMLElement;
    phone: HTMLElement;
  };

  validateForm(): boolean {
    this.formFields = {
      address: document.getElementById("address"),
      propertyType: document.getElementById("property-type"),
      systemSize: document.getElementById("system-size"),
      roofMaterial: document.getElementById("roof-material"),
      name: document.getElementById("name"),
      email: document.getElementById("email"),
      phone: document.getElementById("phone"),
    };

    this.addressInvalidErrorMessage = fieldValidation(
      this.formFields.address,
      isNotEmpty
    )
      ? null
      : "Required field";

    this.nameInvalidErrorMessage = fieldValidation(
      this.formFields.name,
      isNotEmpty
    )
      ? null
      : "Required field";

    this.emailInvalidErrorMessage = fieldValidation(
      this.formFields.email,
      isEmail
    )
      ? null
      : "Invalid email provided";

    if (this.validatePhoneNumber) {
      this.phoneInvalidErrorMessage = fieldValidation(
        this.formFields.phone,
        isNumber
      )
        ? null
        : "Invalid phone number provided";
    } else this.phoneInvalidErrorMessage = null;

    let valid = true;
    valid = this.nameInvalidErrorMessage ? false : valid;
    valid = this.emailInvalidErrorMessage ? false : valid;
    valid = this.phoneInvalidErrorMessage ? false : valid;
    return valid;
  }

  sendEmail(): void {
    if (this.validateForm()) {
      try {
        emailjs.sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_QUOTE_FORM_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_EMAILJS_USER_ID
        );
        this.$toast.open({
          message: "Request sent",
          type: "success",
          duration: 4000,
          dismissible: true,
        });
        this.resetForm();
      } catch (error) {
        console.log({ error });
        this.$toast.open({
          message: "Form submission error",
          type: "error",
          duration: 4000,
          dismissible: true,
        });
      }
    } else {
      this.$toast.open({
        message: "Quote form invalid",
        type: "error",
        duration: 4000,
        dismissible: true,
      });
    }
  }

  resetForm(): void {
    this.address = null;
    this.propertyType = "House";
    this.systemSize = "1.5 kW";
    this.roofMaterial = "Tin";
    this.name = null;
    this.email = null;
    this.phone = null;
  }
}
</script>
