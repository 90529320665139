
import "./deals-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_DEALS_PAGE } from "@/store/modules/deals-page/deals-page.actions";
import { DEALS_PAGE_ENTRY_ID } from "@/store/api.config";
import { DealsPageEntity } from "@/store/modules/deals-page/deals-page.model";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "DealsPage",
  components: {
    Page,
    Panel,
    RichTextRenderer,
  },
})
export default class DealsPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Our Deals",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  dealsPageEntity: DealsPageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_DEALS_PAGE, DEALS_PAGE_ENTRY_ID);
    this.dealsPageEntity = this.$store.getters.dealsPageEntity;

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getDealsPageSplashImageUrl(): string {
    return getImageUrlFromContentfulEntity(
      this.dealsPageEntity.dealsPageSplashImage
    );
  }
  //#endregion
}
DealsPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
