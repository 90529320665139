
import "./contact-form.scss";
import "@/validation/validator.scss";
import { Options, Vue } from "vue-class-component";
import emailjs from "emailjs-com";
import {
  isNotEmpty,
  isEmail,
  isNumber,
  fieldValidation,
} from "@/validation/validator";

@Options({
  name: "ContactForm",
  components: {},
})
export default class ContactForm extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };

  validatePhoneNumber = false;

  name: string | null = null;
  email: string | null = null;
  phone: string | null = null;
  message: string | null = null;

  nameInvalidErrorMessage: string | null = null; // Required field
  emailInvalidErrorMessage: string | null = null; // Invalid email provided
  phoneInvalidErrorMessage: string | null = null; // Invalid phone number provided
  messageInvalidErrorMessage: string | null = null; // Required field

  formFields: {
    name: HTMLElement;
    email: HTMLElement;
    phone: HTMLElement;
    message: HTMLElement;
  };

  validateForm(): boolean {
    this.formFields = {
      name: document.getElementById("name"),
      email: document.getElementById("email"),
      phone: document.getElementById("phone"),
      message: document.getElementById("message"),
    };

    this.nameInvalidErrorMessage = fieldValidation(
      this.formFields.name,
      isNotEmpty
    )
      ? null
      : "Required field";

    this.emailInvalidErrorMessage = fieldValidation(
      this.formFields.email,
      isEmail
    )
      ? null
      : "Invalid email provided";

    if (this.validatePhoneNumber) {
      this.phoneInvalidErrorMessage = fieldValidation(
        this.formFields.phone,
        isNumber
      )
        ? null
        : "Invalid phone number provided";
    } else this.phoneInvalidErrorMessage = null;

    this.messageInvalidErrorMessage = fieldValidation(
      this.formFields.message,
      isNotEmpty
    )
      ? null
      : "Required field";

    let valid = true;
    valid = this.nameInvalidErrorMessage ? false : valid;
    valid = this.emailInvalidErrorMessage ? false : valid;
    valid = this.phoneInvalidErrorMessage ? false : valid;
    valid = this.messageInvalidErrorMessage ? false : valid;
    return valid;
  }

  sendEmail(): void {
    if (this.validateForm()) {
      try {
        emailjs.sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_CONTACT_FORM_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_EMAILJS_USER_ID
        );
        this.$toast.open({
          message: "Request sent",
          type: "success",
          duration: 4000,
          dismissible: true,
        });
        this.resetForm();
      } catch (error) {
        console.log({ error });
        this.$toast.open({
          message: "Form submission error",
          type: "error",
          duration: 4000,
          dismissible: true,
        });
      }
    } else {
      this.$toast.open({
        message: "Contact form invalid",
        type: "error",
        duration: 4000,
        dismissible: true,
      });
    }
  }

  resetForm(): void {
    this.name = null;
    this.email = null;
    this.phone = null;
    this.message = null;
  }
}
