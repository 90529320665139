
import "./quote-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import QuoteForm from "@/components/quote-form/quote-form.vue";
import { FETCH_QUOTE_PAGE } from "@/store/modules/quote-page/quote-page.actions";
import { QUOTE_PAGE_ENTRY_ID } from "@/store/api.config";
import { QuotePageEntity } from "@/store/modules/quote-page/quote-page.model";

@Options({
  name: "QuotePage",
  components: {
    Page,
    QuoteForm,
  },
})
export default class QuotePage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Request a Quote",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  quotePageEntity: QuotePageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_QUOTE_PAGE, QUOTE_PAGE_ENTRY_ID);
    this.quotePageEntity = this.$store.getters.quotePageEntity;

    this.isLoading = false;
  }
  //#endregion
}
QuotePage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
