<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Spartan Energy` : `Spartan Energy`
    }}</template>
  </metainfo>
  <div v-if="!this.isLoading">
    <Page :hasSplash="false">
      <template #pageContent>
        <div class="contact-page">
          <ContactForm class="contact-form-container" />
        </div>
      </template>
    </Page>
  </div>
  <Footer />
</template>

<script lang="ts">
import "./contact-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import ContactForm from "@/components/contact-form/contact-form.vue";

@Options({
  name: "ContactPage",
  components: {
    Page,
    ContactForm,
  },
})
export default class ContactPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Contact Us",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    this.isLoading = false;
  }
  //#endregion
}
ContactPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
</script>
